:root {
  --gap: 32px;
}

.dashboard {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 240px;
  background-color: #1c1f2e;
  color: white;
  padding-top: 20px;
}

.logo-container {
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
}

.logo {
  width: 140px;
  height: auto;
  margin-right: 10px;
}

.brand-text {
  color: white;
  font-size: 24px;
}

.sidebar .nav-link {
  color: #a0a3b1;
  padding: 10px 20px;
}

.sidebar .nav-link.active {
  background-color: #2d3247;
  color: white;
}

.main-content {
  flex-grow: 1;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
}

.top-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 40px;
  background-color: white;
}

.user-menu {
  font-size: 14px;
}

.custom-container {
  width: 100%;
  padding-right: var(--gap);
  padding-left: var(--gap);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
}

.custom-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.content-area {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

/* Mobile styles */
@media (max-width: 991px) {
  .dashboard {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    padding-top: 10px;
    background-color: #1c1f2e !important;
  }

  .navbar-brand {
    padding-left: 20px;
  }

  .navbar-toggler {
    margin-right: 20px;
  }

  .user-menu-mobile {
    color: white;
    padding: 10px 20px;
    border-bottom: 1px solid #2d3247;
    margin-bottom: 10px;
  }

  .logo {
    width: 100px;
    height: auto;
    margin-right: 10px;
  }
}

/* Desktop styles */
@media screen and (min-width: 1024px) {
  .custom-container {
    max-width: calc(960px - (2 * var(--gap)));
  }
}

/* Widescreen styles */
@media screen and (min-width: 1216px) {
  .custom-container {
    max-width: calc(1152px - (2 * var(--gap)));
  }
}

/* Fullhd styles */
@media screen and (min-width: 1408px) {
  .custom-container {
    max-width: calc(1344px - (2 * var(--gap)));
  }
}